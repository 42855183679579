<template lang="pug">
  .subscriber-login.view
    section.centered.subscriber-login-title
      .section-content-wrapper
          h1 Авторизация
          .subscriber-login-form
            h3(v-if="login_form.step === 0") Укажите свой номер телефона:
            h3(v-else-if="login_form.step === 1") Повторите символы с картинки:
            el-form(:model="login_form" @keydown.enter.native.prevent="login" :rules="login_form_rules" ref="subscriber_login_form")
              .step-0(v-show="login_form.step === 0")
                el-form-item
                  nobr +7
                el-form-item(prop="code", label="")
                  el-input.code(v-model="login_form.code" placeholder="000" maxlength="3" ref="subscriber_login_form_code")
                el-form-item(prop="number", label="")
                  el-input.number(v-model="login_form.number" placeholder="0000000" maxlength="7" ref="subscriber_login_form_number")
              .step-1(v-show="login_form.step === 1")
                el-form-item
                  img(:src="captcha.img")
                  el-progress(:percentage="captcha_progress" :status="captcha_progress_status" :stroke-width="2" :show-text="false")
                el-form-item(prop="captcha", label="")
                  el-input.captcha(v-model="login_form.captcha" maxlength="4" ref="subscriber_login_form_captcha")
                el-button(@click="login" type="primary") Далее
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {GET_CAPTCHA, SUBSCRIBER_LOGIN} from "@/store/types"

const default_login_form = () => {
  return {
    step: 0,
    code: '',
    number: '',
    captcha: '',
    phone: '',
  }
}

export default {
  metaInfo() {
    return {
      title: this.title
    }
  },
  data() {
    return {
      captcha_lifetime: 60,
      captcha_timeout: undefined,
      captcha_progress: 100,
      captcha_progress_status: 'success',
      captcha_progress_timeout: undefined,
      captcha: {
        img: undefined,
      },
      login_form: default_login_form(),
      login_form_rules: {
        code: [
          {required: true, message: 'Код города', trigger: 'blur'},
          {min: 3, max: 3, message: '3 цифры', trigger: 'blur'},
          {min: 3, max: 3, message: '3 цифры', trigger: 'blur'},
        ],
        number: [
          {required: true, message: 'Номер телефона', trigger: 'blur'},
          {min: 7, max: 7, message: '7 цифр', trigger: 'blur'},
        ],
        captcha: [
          {required: true, message: 'Символы', trigger: 'blur'},
          {min: 4, max: 4, message: '4 символа', trigger: 'blur'},
        ],
      },
    }
  },
  computed: {
    ...mapGetters([
      'subscriber'
    ]),
    phone() {
      return '7' + this.login_form.code + this.login_form.number
    },
    title() {
      return 'Авторизация'
    },
  },
  mounted() {
    this.resetLoginForm()

    this.$watch('login_form', () => {
      if (this.login_form.code.length === 3 && this.login_form.step === 0) this.$refs.subscriber_login_form_number.focus()
      if (this.login_form.code.length === 3 && this.login_form.number.length === 7 && this.login_form.step === 0) {
        this.login_form.step = 1

        this.$refs.subscriber_login_form_captcha.focus()

        if (window.gtag !== undefined){
          gtag('event', 'type_phone', {
            'event_category': 'onboarding'
          })
          console.log('GA goal reached: type_phone')
        }

        if (window.ym !== undefined) {
          window.ym(74625946, 'reachGoal', 'type_phone')
          console.log('YM goal reached: type_phone')
          window.ym(74625946, 'hit', '/#captcha')
          console.log('URL hit: /#captcha')
        }
      }
    }, {
      // Run automatically?
      immediate: false,
      // Watch all properties changes
      deep: true
    })
  },
  beforeRouteLeave(to, from, next) {
    this.resetCaptchaProgress()

    next()
  },
  methods: {
    ...mapActions([
      GET_CAPTCHA,
      SUBSCRIBER_LOGIN,
    ]),
    resetLoginForm() {
      this.login_form = default_login_form()

      this.refreshCaptcha()

      this.$refs.subscriber_login_form_code.focus()
    },
    resetCaptchaProgress() {
      window.clearTimeout(this.captcha_timeout)
      window.clearTimeout(this.captcha_progress_timeout)

      this.captcha_progress = 100
      this.captcha_progress_status = 'success'
    },
    refreshCaptcha() {
      this.resetCaptchaProgress()

      return this.GET_CAPTCHA().then((captcha) => {
        Object.assign(this.captcha, captcha)

        this.captcha_timeout = window.setTimeout(() => {
          this.refreshCaptcha()
        }, this.captcha_lifetime * 1000)
      }).then(() => {
        this.progressCaptcha()
      }).catch((error) => {
        if(error.response.data.errors){
          this.$root.pushAppMessages(error.response.data.errors.map((message)=>{
            return this.$t(message)
          }))
        }

        return Promise.reject(error)
      })
    },
    progressCaptcha() {
      this.captcha_progress_timeout = window.setTimeout(() => {
        if (!this.captcha_progress) this.resetCaptchaProgress()

        this.captcha_progress -= .1

        if (this.captcha_progress < 66.6) this.captcha_progress_status = 'warning'
        if (this.captcha_progress < 33.3) this.captcha_progress_status = 'exception'

        this.progressCaptcha()
      }, this.captcha_lifetime)
    },
    login() {
      this.$refs.subscriber_login_form.validate((valid) => {
        if (valid) {
          this.SUBSCRIBER_LOGIN({...this.login_form, ...{phone: this.phone, key: this.captcha.key}})
              .then(() => {
                this.$router.push({
                  name: 'subscriber.verification',
                  params: {verification_uid: this.subscriber.verification_uid}
                })
          }).catch((error) => {
            this.resetLoginForm()

            if(error.response.data.errors){
              this.$root.pushAppMessages(error.response.data.errors.map((message)=>{
                return this.$t(message)
              }))
            }

            return Promise.reject(error)
          })
        } else return false;
      })
    },
    logout() {
      this.RESET_STORE()
    },
  },
}

</script>

<style lang="scss" scoped>
.subscriber-login {
  &-title{
    background: $color--background;
    color: $color--black;
  }
  &-form {
    background: $color--background;
    color: $color--black;

    text-align: center;

    .el-form {
      display: inline-block;
      text-indent: 0;
      overflow: hidden;
    }

    .step-0, .step-1 {
      display: flex;
      align-items: center;

      > * {
        display: block;
        margin-right: .5rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .el-form-item {
        margin-bottom: 0;
      }

      nobr {
        @include font--medium;
      }

      .code {
        width: 5em;
      }

      .number {
        width: 10em;
      }

      .captcha {
        width: 5em;
      }
    }
  }
}
</style>
